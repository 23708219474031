/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [
  // {
  //   url: '/pages/withdraw',
  //   name: "Withdraw",
  //   icon: "HashIcon",
  //   slug: 'WithDraw'
  // },
  // {
  //   url: "/visual",
  //   name: "Visual",
  //   icon: "BarChart2Icon",
  //   slug: "visual",
  // },
  // {
  //   url: "/table",
  //   name: "Table",
  //   icon: "BoxIcon",
  //   slug: "table",
  // },
  {
    url: null,
    name: "Izoofficialmusic",
    icon: "PieChartIcon",
    submenu: [
      {
        url: "/instagram/user/izoofficialmusic",
        name: "Overview",
        slug: "IzoofficialmusicNew",
      },

      {
        url: "/instagram/post/izoofficialmusic",
        name: "Posts",
        slug: "IzoofficialmusicNew",
      },

      {
        url: "/instagram/word_cloud/izoofficialmusic",
        name: "Word Cloud ",
        icon: "BarChart2Icon",
        slug: "IzoofficialmusicNew",
      },
      // {
      //   url: "/instagram/twitter/izoofficialmusic",
      //   name: "Twitter ",
      //   icon: "BarChart2Icon",
      //   slug: "IzoofficialmusicNew",
      // },
    ],
  },
  {
    url: null,
    name: "Iyla",
    icon: "PieChartIcon",
    submenu: [
      {
        url: "/instagram/user/iyla",
        name: "Overview",
        slug: "iyla-user",
      },
      {
        url: "/instagram/post/iyla",
        name: "Posts",
        slug: "iyla-ecommerce-shop",
      },

      {
        url: "/instagram/word_cloud/iyla",
        name: "Word Cloud ",
        icon: "BarChart2Icon",
        slug: "iyla-word-cloud",
      },
      {
        url: "/instagram/twitter/izoofficialmusic",
        name: "Twitter ",
        icon: "BarChart2Icon",
        slug: "IzoofficialmusicNew",
      },
    ],
  },
  // {
  //   url: null,
  //   name: "Izoofficialmusic",
  //   icon: "PieChartIcon",
  //   submenu: [
  //     {
  //       url: "/izoOfficial/overview",
  //       name: "Overview",
  //       slug: "ecommerce-shop",
  //     },
  //     {
  //       url: "/izoOfficial/post",
  //       name: "Posts",
  //       slug: "ecommerce-shop",
  //     },
  //     // {
  //     //   url: "/izoOfficial/charts",
  //     //   name: "Charts",
  //     //   slug: "ecommerce-wish-list",
  //     // },

  //     {
  //       url: "/wordCloudIzo",
  //       name: "Word Cloud ",
  //       icon: "BarChart2Icon",
  //       slug: "visual",
  //     },
  //   ],
  // },
  // {
  //   url: null,
  //   name: "Iyla",
  //   icon: "PieChartIcon",
  //   // slug: "visual",
  //   submenu: [
  //     {
  //       url: "/iyla/overview",
  //       name: "Overview",
  //       slug: "ecommerce-shop",
  //     },
  //     {
  //       url: "/iyla/post",
  //       name: "Posts",
  //       slug: "ecommerce-shop",
  //     },

  //     // {
  //     //   url: "/iyla/charts",
  //     //   name: "Charts",
  //     //   slug: "ecommerce-shop",
  //     // },
  //     {
  //       url: "/wordCloudIyla",
  //       name: "Word Cloud ",
  //       icon: "BarChart2Icon",
  //       slug: "visual",
  //     },
  //   ],
  // },
];
