<!-- =========================================================================================
    File Name: TheFooter.vue
    Description: Footer component
    Component Name: TheFooter
========================================================================================== -->


<template functional>
    <footer class="the-footer flex-wrap justify-between" :class="classes">
<!--        <span> I3, &copy; All rights Reserved For Sishemi Co</span>-->
    </footer>
</template>
<script>

export default {
    name: "the-footer",
    props: {
        classes: {
            type: String,
        },
    },

}
</script>
